import React, { useEffect, useState } from 'react';
import { Modal, Spin, message } from 'antd';
import { Formik } from 'formik';
import { Form, Switch } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { Table, TableActions } from '~/components/Table';
import errorHandler from '~/Utils/errorHandler';
import { ModalFooter } from '~/components/Modal';

export default function UserRoles({ visible, onClose, userData }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [rolesList, setRolesList] = useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/roles');
      setRecordList(data.data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);

    try {
      const permissions = Object.keys(values).filter(perm => {
        return values[perm] === true;
      });

      await api.put(`users/${userData.id}/roles`, permissions);
      message.success(t('messages:success'));

      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) fetchData();
    else setRolesList({});
  }, [visible]);

  useEffect(() => {
    const roles = {};
    if (recordList && userData && userData.roles) {
      recordList.map(item => {
        roles[item.slug] = userData.roles.includes(item.slug);
        return 0;
      });
    }
    setRolesList(roles);
  }, [recordList]);

  const tableColumns = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return <span>{record.name}</span>;
      },
    },
    {
      dataIndex: 'active',
      key: 'slug',
      width: '45px',
      render: (text, record) => {
        return (
          <TableActions key={`actions.${record.id}`}>
            <Switch size="small" key={record.id} name={record.slug} />
          </TableActions>
        );
      },
    },
  ];

  return (
    <Formik initialValues={rolesList} enableReinitialize onSubmit={handleSave}>
      {({ isSubmitting, resetForm, submitForm }) => (
        <Modal
          visible={visible}
          onCancel={onClose}
          afterClose={resetForm}
          loading={loading || isSubmitting}
          title={t('screens:roles.title')}
          footer={<ModalFooter onOk={submitForm} onCancel={onClose} />}
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Table
                showHeader={false}
                pagination={false}
                rowKey="id"
                size="small"
                columns={tableColumns}
                loading={loading || isSubmitting}
                dataSource={recordList}
              />
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}

UserRoles.defaultProps = {};
UserRoles.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userData: PropTypes.objectOf().isRequired,
};
