import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Modal, Spin, Tag, message } from 'antd';
import { Form, Switch } from 'formik-antd';
import PropTypes from 'prop-types';
import { Table, TableActions } from '~/components/Table';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { ModalFooter } from '~/components/Modal';

export default function PermissionsForm({ roleID, rolePermissions, onClose, visible }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [rolePermissionsList, setRolePermissionsList] = useState([]);

  const fetchRecords = async () => {
    setLoading(true);
    setRecordList([]);
    try {
      const { data } = await api.get('/permissions');
      setRecordList(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async values => {
    try {
      const slugs = Object.keys(values).filter(role => {
        return values[role] === true;
      });
      await api.put(`/permission-roles/${roleID}`, slugs);
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (recordList.length > 0 && rolePermissions) {
      const roles = {};
      recordList.forEach(item => {
        item.permissions.forEach(perm => {
          roles[perm.slug] = rolePermissions.includes(perm.slug);
        });
      });
      setRolePermissionsList(roles);
    }
  }, [recordList, rolePermissions]);

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
  }, [visible]);

  const tableColumns = [
    {
      title: t('screens:permissions.data.permissions'),
      key: 'name',
      dataIndex: 'name',
    },
  ];

  const permissionsColumns = [
    {
      title: t('screens:roles.permission'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            {record.name}
            <small>
              <Tag style={{ fontSize: '12px' }}>{record.slug}</Tag>
            </small>
          </span>
        );
      },
    },
    {
      key: 'active',
      width: '45px',
      render: (text, record) => (
        <TableActions key={`actions.${record.id}`}>
          <Switch size="small" key={record.id} name={record.slug} />
        </TableActions>
      ),
    },
  ];

  const renderPermissions = record => {
    return (
      <Table
        actionSize="78px"
        size="small"
        showHeader={false}
        pagination={false}
        dataSource={record.permissions}
        columns={permissionsColumns}
      />
    );
  };

  return (
    <Formik initialValues={rolePermissionsList} enableReinitialize onSubmit={handleSave}>
      {({ isSubmitting, resetForm, submitForm }) => (
        <Modal
          onCancel={onClose}
          afterClose={resetForm}
          title={t('screens:permissions.title')}
          visible={visible}
          footer={<ModalFooter onOk={submitForm} onCancel={onClose} />}
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Table
                expandRowByClick
                showHeader={false}
                pagination={false}
                rowKey="order"
                size="small"
                columns={tableColumns}
                loading={loading || isSubmitting}
                dataSource={recordList}
                expandedRowRender={renderPermissions}
              />
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}

PermissionsForm.propTypes = {
  roleID: PropTypes.number,
  rolePermissions: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

PermissionsForm.defaultProps = {
  roleID: null,
  rolePermissions: [],
  onClose: () => {},
  visible: false,
};
