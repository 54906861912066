import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaEye, FaPencilAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import Button from '~/components/Button';
import Can from '~/components/Can';

export function CanEditRender({ categoryId, record, handleEdit }) {
  CanEditRender.propTypes = {
    categoryId: PropTypes.number.isRequired,
    record: PropTypes.instanceOf.isRequired,
    handleEdit: PropTypes.func.isRequired,
  };
  const { permissions } = useSelector(state => state.user);
  const { t } = useTranslation();

  // Função auxiliar para renderizar os botões de acordo com a permissão e categoria
  const renderButton = (editPermission, viewPermission, editCategoryId) => {
    if (permissions?.includes(editPermission) && categoryId === editCategoryId) {
      return (
        <Button title={t('messages:edit')} onClick={() => handleEdit(record)}>
          <FaPencilAlt />
        </Button>
      );
    }

    if (categoryId === editCategoryId && !permissions?.includes(editPermission)) {
      return (
        <Can permission={viewPermission}>
          <Button title={t('messages:view')} onClick={() => handleEdit(record)}>
            <FaEye />
          </Button>
        </Can>
      );
    }

    return null;
  };

  return (
    <>
      {renderButton('@contract/editOnlyHostType', '@contract/view', 1)}
      {renderButton('@contract/editOnlyProductType', '@contract/view', 2)}
      {renderButton('@contract/editOnlyDevelopmentType', '@contract/view', 3)}
    </>
  );
}

export const permissionDisable = (permissions, categoryId) => {
  if (permissions?.includes('@contract/edit')) {
    return false;
  }
  if (permissions?.includes('@contract/editOnlyProductType') && categoryId === 2) {
    return false;
  }
  if (permissions?.includes('@contract/editOnlyHostType') && categoryId === 1) {
    return false;
  }
  if (permissions?.includes('@contract/editOnlyDevelopmentType') && categoryId === 3) {
    return false;
  }
  return true;
};
