import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select, Form } from 'formik-antd';
import { Modal, Spin, Switch } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import { ModalFooter } from '~/components/Modal';
import MaskedInput from 'antd-mask-input';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { HeaderInfo } from './styles';
import MyModal from './MyModal';

function RegisterW41Edit({ record, handleBack, sendDataToServer, news }) {
  const { t } = useTranslation();

  const [customerId, setCustomerId] = useState(record.customer_id);
  const [active, setActive] = useState(record.active);
  const [deviceName, setDeviceName] = useState(record.device_name);
  const [ip, setIP] = useState(record.ip);
  const [port, setPort] = useState(record.port);
  const [user, setUser] = useState(record.user);
  const [password, setPassword] = useState(record.password);
  const [obs, setObs] = useState(record.obs);
  const [type, setType] = useState(record.type ? record.type : 'Servidor');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState('');
  const [selectLoading, setSelectLoading] = useState(false);
  const [listOfCustomers, setListOfCustomers] = useState([]);
  const [customerName, setCustomerName] = useState('');

  const productSchema = Yup.object().shape({
    deviceName: Yup.string()
      .required('É obrigatório o nome do dispositivo')
      .nullable(),
    ip_field: Yup.string().nullable(),
  });

  const handleSave = () => {
    const data = {
      id: record.id ? record.id : '0',
      active,
      customer_id: customerId,
      device_name: deviceName,
      ip,
      port,
      user,
      password,
      type,
      obs,
    };
    sendDataToServer(data);
    handleBack(0);
  };

  const typeOptions = [
    'Servidor',
    'Firewall',
    'Access Point',
    'Roteador',
    'Modem',
    'Armazenamento',
    'Impressora',
    'DVR',
    'Relógio de Ponto',
    'Equipamento Médico',
    'Outros',
  ];

  const fetchCustomer = async (page, search) => {
    if (!hasMore) return;
    setSelectLoading(true);
    try {
      const response = await api.get('/customers', {
        params: {
          page,
          perPage: 20,
          name: search,
        },
      });

      const { data: newOptions, meta } = response.data;

      if (page === 1 || listOfCustomers === null) {
        setListOfCustomers(newOptions);
      } else {
        setListOfCustomers(prevCustomers => [...prevCustomers, ...newOptions]);
      }

      setHasMore(page < meta.last_page);
    } catch (error) {
      errorHandler(error);
    }
    setSelectLoading(false);
  };

  const handleScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const count = scrollHeight - scrollTop <= clientHeight;

    if (count && !selectLoading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleSearch = () => {
    setHasMore(true);
    setPage(1);
    fetchCustomer(page, search);
  };

  useEffect(() => {
    fetchCustomer(page, search);
  }, [page]);

  const modalReturn = e => {
    setIsModalOpen(false);
    if (e) handleBack(0);
  };

  return (
    <>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={record}
        enableReinitialize
        onSubmit={handleSave}
        validationSchema={productSchema}
      >
        {({ errors }) => (
          <Form width="600px">
            <Input type="hidden" name="id" />
            <Modal
              title={t('screens:w41.register')}
              onCancel={modalReturn}
              visible
              width="600px"
              footer={<ModalFooter onOk={handleSave} onCancel={modalReturn} />}
            >
              {!(news === 'yes') ? (
                <Row>
                  <FormControl
                    cols={{ xs: 2 }}
                    field="active"
                    label={t('screens:customers.data.active')}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Switch
                      // disabled={permissions && !permissions.includes('@contract/edit')}
                      checked={active}
                      onChange={e => {
                        setActive(e);
                      }}
                      name="active"
                      style={{ margin: 'auto', marginTop: '5px' }}
                    />
                  </FormControl>
                  <FormControl field="dates" cols={{ xs: 22 }}>
                    <HeaderInfo>
                      <Row>
                        <span>
                          {`Data do Cadastro ${new Date(record.created_at).toLocaleString('pt-BR', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}`}
                        </span>
                      </Row>
                      {record.name ? (
                        <Row>
                          <span>
                            {`Alterado em ${new Date(record.updated_at).toLocaleString('pt-BR', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                            })} por ${record.name}`}
                          </span>
                        </Row>
                      ) : (
                        ''
                      )}
                    </HeaderInfo>
                  </FormControl>
                </Row>
              ) : (
                ''
              )}
              {news === 'yes' ? (
                <Row>
                  <FormControl
                    cols={{ sm: 24 }}
                    // error={errors.customer_id}
                    required
                    field="customer_id"
                    label={t('screens:contracts.data.customer_id')}
                  >
                    <Select
                      name="customer_id"
                      showSearch
                      filterOption={false}
                      onSearch={value => {
                        setSearch(value);
                        setListOfCustomers(null);
                      }}
                      onInputKeyDown={e => {
                        console.log(e.key);
                        if (e.key === 'Enter') {
                          console.log('entrou!');
                          e.preventDefault();
                          handleSearch(); // Chama handleSearch apenas quando "Enter" é pressionado
                        }
                      }}
                      onPopupScroll={handleScroll}
                      defaultActiveFirstOption={false}
                      placeholder={t('messages:select')}
                      onSelect={(value, option) => {
                        setCustomerName(option?.props?.children);
                        setCustomerId(value);
                      }}
                      style={{ textTransform: 'uppercase' }}
                      notFoundContent={selectLoading ? <Spin /> : 'No more data'}
                      value={record?.customer?.name ?? customerName}
                    >
                      {listOfCustomers?.length > 0 &&
                        listOfCustomers.map(item => {
                          return (
                            <Select.Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                              {!item.active ? <b style={{ color: '#ff000078' }}>{item.name}</b> : item.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Row>
              ) : (
                ''
              )}
              <Row>
                <FormControl
                  // error={errors.code_seq}
                  cols={{ sm: 24 }}
                  field="device_name"
                  label={t('screens:w41.data.device_name')}
                >
                  <Input
                    name="device_name"
                    style={{ textTransform: 'uppercase' }}
                    value={deviceName}
                    onChange={e => {
                      setDeviceName(e.target.value);
                    }}
                    //   disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                  />
                </FormControl>
              </Row>
              <Row>
                <FormControl error={errors.ip_field} cols={{ sm: 8 }} field="ip_field" label={t('screens:w41.data.ip')}>
                  <Input
                    name="ip_field"
                    value={`${ip}`}
                    onChange={e => {
                      setIP(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  // error={errors.code_seq}
                  cols={{ sm: 4 }}
                  field="port"
                  label={t('screens:w41.data.port')}
                >
                  <MaskedInput
                    name="ip_field"
                    mask="11111"
                    value={`${port}`}
                    onChange={e => {
                      setPort(e.target.value);
                    }}
                    placeholder="_____"
                  />
                </FormControl>
                <FormControl
                  // error={errors.code_seq}
                  cols={{ sm: 12 }}
                  field="type"
                  label={t('screens:w41.data.type')}
                >
                  <Select
                    // disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                    showSearch
                    name="type"
                    optionFilterProp="children"
                    onChange={value => {
                      setType(value);
                    }}
                    placeholder="Selecione..."
                    style={{ textTransform: 'uppercase' }}
                    value={type}
                  >
                    {typeOptions.map(item => {
                      return (
                        <Select.Option key={item} value={item} style={{ textTransform: 'uppercase' }}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Row>
              <Row>
                <FormControl
                  // error={errors.code_seq}
                  cols={{ sm: 12 }}
                  field="user"
                  label={t('screens:w41.data.user')}
                >
                  <Input
                    name="user"
                    // style={{ textTransform: 'uppercase' }}
                    value={user}
                    onChange={e => {
                      setUser(e.target.value);
                    }}
                    //   disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                  />
                </FormControl>
                <FormControl
                  // error={errors.code_seq}
                  cols={{ sm: 12 }}
                  field="password"
                  label={t('screens:w41.data.password')}
                >
                  <Input
                    name="password"
                    // style={{ textTransform: 'uppercase' }}
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                    //   disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                  />
                </FormControl>
              </Row>
              <Row>
                <FormControl
                  // error={errors.code_seq}
                  cols={{ sm: 24 }}
                  field="obs"
                  label={t('screens:w41.data.obs')}
                >
                  <Input.TextArea
                    name="obs"
                    // onKeyDown={handleKeyDown}
                    rows={obs ? obs.split(/\r\n|\r|\n/).length : 3}
                    style={{ textTransform: 'uppercase' }}
                    value={obs}
                    onChange={e => {
                      setObs(e.target.value);
                    }}
                    //   disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                  />
                </FormControl>
              </Row>
            </Modal>
          </Form>
        )}
      </Formik>
      <MyModal visible={isModalOpen} modalReturn={modalReturn} />
    </>
  );
}

export default React.memo(RegisterW41Edit);

RegisterW41Edit.propTypes = {
  record: PropTypes.objectOf.isRequired,
  handleBack: PropTypes.func.isRequired,
  sendDataToServer: PropTypes.func.isRequired,
  news: PropTypes.string.isRequired,
};
