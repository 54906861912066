import React, { useState, useEffect } from 'react';
import { addMonths, parseISO, subDays, addYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Modal, message, Spin, Icon, Upload, Switch, Col } from 'antd';
import { Form, Input, InputNumber, Select, DatePicker } from 'formik-antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { BsArrowRepeat } from 'react-icons/bs';
import api from '~/services/api';
import Row from '~/components/Row';
import Can from '~/components/Can';
import Button from '~/components/Button';
import { ModalFooter } from '~/components/Modal';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import errorHandler from '~/Utils/errorHandler';
import { useSelector } from 'react-redux';
import { isMoment } from 'moment';
import { dynamicCost } from '~/Utils';
import { Table, TableActions } from '~/components/Table';
import { FaEye, FaRegTrashAlt } from 'react-icons/fa';
import { SpanUpper } from '~/styles/default';
import { HeaderInfo } from './styles';
import { permissionDisable } from './permissions';

const initialValues = {
  price: '',
  cost: '',
  started_at: new Date().toISOString(),
  active: true,
};

const createValidationSchema = isNewCustomer =>
  Yup.object().shape({
    customer_id: Yup.number()
      .integer()
      .nullable(isNewCustomer) // nullable se isNewCustomer for true
      .when([], {
        is: () => !isNewCustomer,
        then: Yup.number().required(), // required se isNewCustomer for false
      }),
    product_id: Yup.number()
      .integer()
      .required(),
    cost: Yup.string()
      .min(0)
      .nullable(),
    price: Yup.string()
      .min(0)
      .nullable(),
    started_at: Yup.date().required(),
    length: Yup.number().required(),
    recurrence: Yup.number()
      .integer()
      .required(),
    notes: Yup.string()
      .min(0)
      .nullable(),
  });

export default function ContractsForm({
  visible,
  onClose,
  customerID,
  contract,
  newCustomer,
  setNewCustomer,
  isNewCustomer,
}) {
  /* eslint-disable react/jsx-wrap-multilines */
  const { t } = useTranslation();
  const { recurrences } = useSelector(state => state.app);
  const [loading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [renewContractExpirationField, setRenewContractExpirationField] = useState(true);
  const [recordData, setRecordData] = useState(initialValues);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [productOptions, setProductOptions] = useState([]);
  const [uploadList, setUploadList] = useState([]);
  const [deletedFile, setDeletedFile] = useState([]);
  // const [deletedFile, setDeletedFile] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState('');
  const permissions = useSelector(state => state.user && state.user.permissions);
  const validationSchema = createValidationSchema(isNewCustomer);

  console.log(permissions);

  const storeFilesToDatabase = async (files, contratoId) => {
    try {
      const formData = new FormData();
      files.forEach(file => {
        const originFile = file.originFileObj || file;
        if (originFile) {
          formData.append('files[]', originFile);
        }
      });

      await api.post(`/contracts/files/${contratoId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Erro ao armazenar arquivos:', error);
    }
  };

  const handleDownload = async id => {
    try {
      const fileInfo = await api.get(`/contracts/files/${id}`);
      window.open(fileInfo.data.url, '_blank');
      message.success(t('messages:success'));
    } catch (error) {
      console.log('error -> handleDownload -> ', error);
      errorHandler(error);
    }
  };

  const handleRemoveUploadListItem = async (file, contractId) => {
    if (file.id) {
      const deletedFilesList = deletedFile;
      deletedFilesList.push(file);
      setDeletedFile(deletedFilesList);
      const data = uploadList.filter(item => item.id !== file.id);
      setUploadList(data);
      await api.delete(`contracts/${contractId}/files/${file.id}`);
    } else {
      const data = uploadList.filter(item => item.uid !== file.uid);
      setUploadList(data);
    }
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      if (values.id) {
        delete values.files;

        await api.put(`/contracts/${values.id}`, values);

        if (uploadList.length > 0) {
          const uploadFiles = uploadList.map(file => (file.originFileObj ? file : null)).filter(file => file !== null);

          if (uploadFiles.length > 0) {
            await storeFilesToDatabase(uploadFiles, values.id);
          }
        }
        message.success(t('messages:successUpdateContract'));
      } else if (isNewCustomer) {
        const { data } = await api.get(`products/${values.product_id}`);
        values.product = JSON.parse(data);
        if (newCustomer.length > 0) {
          const lastTempId = newCustomer[newCustomer.length - 1];
          values.temp_id = lastTempId.temp_id + 1;
        } else {
          values.temp_id = 1;
        }
        if (uploadList.length > 0) {
          const uploadFiles = uploadList.map(file => (file.originFileObj ? file : null)).filter(file => file !== null);
          values.uploadFiles = uploadFiles;
        }
        setNewCustomer(prevValues => [...prevValues, values]);
      } else {
        const response = await api.post('/contracts', values);

        if (uploadList.length > 0) {
          const uploadFiles = uploadList.map(file => (file.originFileObj ? file : null)).filter(file => file !== null);

          if (uploadFiles.length > 0) {
            await storeFilesToDatabase(uploadFiles, response.id);
          }
        }
        message.success(t('messages:successContract'));
      }
      setRenewContractExpirationField(true);
      setUploadList([]);
      onClose();
    } catch (error) {
      console.log(error);
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchThisCustomer = async () => {
    try {
      const { data } = await api.get(`/customers/${customerID}`);

      setRecordData(prevRecordData => ({
        ...prevRecordData,
        customer_id: data.id,
        customer: {
          name: data.name,
        },
      }));
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchCustomer = async (page, search) => {
    if (!hasMore) return;
    setSelectLoading(true);
    try {
      const response = await api.get('/customers', {
        params: {
          page,
          perPage: 20,
          name: search,
        },
      });

      const { data: newOptions, meta } = response.data;

      if (page === 1 || customerOptions === null) {
        setCustomerOptions(newOptions);
      } else {
        setCustomerOptions(prevCustomers => [...prevCustomers, ...newOptions]);
      }

      setHasMore(page < meta.last_page);
    } catch (error) {
      errorHandler(error);
    }
    setSelectLoading(false);
  };

  const fetchProducts = async () => {
    try {
      const { data } = await api.get('/products/actives');
      setProductOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchRecord = async () => {
    setLoading(true);
    try {
      if (contract?.id) {
        setRecordData(contract);
        setUploadList(contract.files);
      } else {
        setRecordData({ ...initialValues });
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSelectProduct = (value, values) => {
    if (value) {
      const product = productOptions.find(item => item.id === value);

      if (!product) return;

      const { cost, price, length, recurrence } = product;
      const started_at = values.started_at || initialValues.started_at;
      let expiration = null;

      if (started_at && length) {
        const startDate = isMoment(started_at) ? started_at.toISOString() : started_at;
        expiration = subDays(addMonths(parseISO(startDate), length), 1);
      }

      setRecordData({
        ...values,
        cost: cost || null,
        price: price || null,
        length: Number(length) || '',
        recurrence: Number(recurrence) || null,
        product_id: value,
        expiration,
      });
    }
  };

  const handleExpiration = values => {
    const { started_at, length } = values;

    if (started_at && length) {
      const startDate = isMoment(started_at) ? started_at.toISOString() : started_at;
      const expiration = subDays(addMonths(parseISO(startDate), length), 1);
      setRecordData({ ...values, expiration });
    }
  };

  const handleRenewContract = values => {
    const { expiration } = values;
    let newExpiration = addYears(parseISO(expiration), 1);
    if (Object.prototype.toString.call(newExpiration) === '[object Date]') {
      if (Number.isNaN(newExpiration.getTime())) {
        newExpiration = addYears(expiration, 1);
      }
    }
    setRecordData({ ...values, expiration: newExpiration });
    setRenewContractExpirationField(false);
  };

  const handleKeyDown = e => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const count = scrollHeight - scrollTop <= clientHeight;

    if (count && !selectLoading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handlePermissionsCostValue = (record, type) => {
    const value = record[type];

    if (permissions?.includes('@costValue/view')) {
      return value ?? '0,00';
    }
    if (record?.product?.subcategory.category_id === 1 && permissions?.includes('@costValue/viewOnlyProduct')) {
      return value ?? '0,00';
    }
    if (record?.product?.subcategory.category_id === 2 && permissions?.includes('@costValue/viewOnlyHost')) {
      return value ?? '0,00';
    }
    if (record?.product?.subcategory.category_id === 3 && permissions?.includes('@costValue/viewOnlyDevelopment')) {
      return value ?? '0,00';
    }

    return '';
  };

  const handleSearch = () => {
    setHasMore(true);
    setPage(1);
    fetchCustomer(page, search);
  };

  useEffect(() => {
    fetchCustomer(page, search);
  }, [page]);

  useEffect(() => {
    if (visible) {
      fetchRecord();
      fetchProducts();

      if (customerID) {
        fetchThisCustomer();
      }
    }
  }, [visible]);

  const tableColumns = [
    {
      title: t('screens:contracts.data.file'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <SpanUpper>{record.name}</SpanUpper>,
    },
    {
      title: t('screens:contracts.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      render: (text, record) => {
        return (
          <TableActions>
            {record.id && (
              <Can permission="@contract/fileDownloadUpload">
                <Button
                  onClick={() => {
                    handleDownload(record.id);
                  }}
                >
                  <FaEye />
                </Button>
              </Can>
            )}
            <Can permission="@contract/delete">
              <Button
                title={t('messages:delete')}
                onClick={() => {
                  handleRemoveUploadListItem(record, record.contract_id);
                }}
              >
                <FaRegTrashAlt color="danger" />
              </Button>
            </Can>
          </TableActions>
        );
      },
    },
  ];

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSave}
    >
      {({ errors, isSubmitting, submitForm, values, resetForm, setValues }) => (
        <Modal
          title={t('screens:contracts.title')}
          onCancel={onClose}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          width="600px"
          footer={
            <ModalFooter
              type={permissions && !permissions.includes('@contract/edit') ? 'info' : ''}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Input type="hidden" name="id" />
              <Row>
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'space-around',
                  }}
                  xs={24}
                >
                  <FormControl field="active" label={t('screens:customers.data.active')}>
                    <Switch
                      disabled={permissionDisable(permissions, values?.product?.subcategory?.category_id)}
                      checked={values.active}
                      onChange={e => {
                        setValues({ ...values, active: e });
                      }}
                      name="active"
                    />
                  </FormControl>
                  {values.id && (
                    <HeaderInfo
                      field="dataCadastro"
                      style={{
                        width: '70%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                      }}
                    >
                      <Row>
                        <span>
                          {`Data do Cadastro
                              ${new Date(values.created_at).toLocaleString('pt-BR', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                              })}`}
                        </span>
                      </Row>
                      <Row>
                        <span>
                          {` Alterado em ${new Date(values.updated_at).toLocaleString('pt-BR', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}`}
                          {values?.user && ` por ${values?.user}`}
                        </span>
                      </Row>
                      {values.ended_at && (
                        <Row>
                          <span>
                            {` Cancelado em
                                ${new Date(values.ended_at).toLocaleString('pt-BR', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}`}
                            {values.ended_by && ` por ${values.ended_by}`}
                          </span>
                        </Row>
                      )}
                    </HeaderInfo>
                  )}
                </Col>
                {!isNewCustomer && (
                  <FormControl
                    cols={{ xs: 24 }}
                    error={errors.customer_id}
                    required
                    field="customer_id"
                    label={t('screens:contracts.data.customer_id')}
                  >
                    <Select
                      name="customer_id"
                      showSearch
                      onSearch={value => {
                        setSearch(value);
                        setCustomerOptions(null);
                      }}
                      onInputKeyDown={e => {
                        console.log(e.key);
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSearch();
                        }
                      }}
                      onSelect={(value, option) => {
                        setCustomerName(option?.props?.children);
                        setRecordData({ ...recordData, customer_id: value });
                      }}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      placeholder={t('messages:select')}
                      disabled={
                        !permissions?.includes('@contract/editInfo') ||
                        permissionDisable(permissions, values?.product?.subcategory?.category_id)
                      }
                      onPopupScroll={handleScroll}
                      notFoundContent={selectLoading ? <Spin /> : 'No more data'}
                      value={values?.customer?.name ?? customerName}
                    >
                      {customerOptions?.length > 0 &&
                        customerOptions.map(item => {
                          return (
                            <Select.Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                              {!item.active ? <b style={{ color: '#ff000078' }}>{item.name}</b> : item.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}
                <FormControl
                  cols={{ xs: 24 }}
                  required
                  error={errors.product_id}
                  field="product_id"
                  label={t('screens:contracts.data.product_id')}
                >
                  <Select
                    onChange={value => handleSelectProduct(value, values)}
                    disabled={
                      !permissions?.includes('@contract/editInfo') ||
                      permissionDisable(permissions, values?.product?.subcategory?.category_id)
                    }
                    name="product_id"
                    showSearch
                    optionFilterProp="children"
                    placeholder={t('messages:select')}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {productOptions.map(item => {
                      return (
                        <Select.Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl error={errors.cost} cols={{ xs: 8 }} field="cost" label={t('screens:contracts.data.cost')}>
                  <InputCurrency
                    disabled={
                      permissionDisable(permissions, values?.product?.subcategory?.category_id) ||
                      !permissions?.includes('@costValue/edit')
                    }
                    name="cost"
                    currency="R$"
                    number={false}
                    value={handlePermissionsCostValue(values, 'cost')}
                    style={{ textAlign: 'right', textTransform: 'uppercase' }}
                    onChange={event => {
                      setTimeout(() => {
                        setValues({ ...values, cost: dynamicCost(event.target.value) });
                      }, 100);
                    }}
                  />
                </FormControl>
                <FormControl
                  error={errors.price}
                  cols={{ xs: 8 }}
                  field="price"
                  label={t('screens:contracts.data.price')}
                >
                  <InputCurrency
                    disabled={
                      permissionDisable(permissions, values?.product?.subcategory?.category_id) ||
                      !permissions?.includes('@costValue/edit')
                    }
                    name="price"
                    currency="R$"
                    number={false}
                    style={{ textAlign: 'right', textTransform: 'uppercase' }}
                    value={handlePermissionsCostValue(values, 'price')}
                    onChange={event => {
                      setTimeout(() => {
                        setValues({ ...values, price: dynamicCost(event.target.value) });
                      }, 100);
                    }}
                  />
                </FormControl>
                <FormControl
                  error={errors.recurrence}
                  cols={{ xs: 8 }}
                  field="recurrence"
                  required
                  label={t('screens:contracts.data.recurrence')}
                >
                  <Select
                    name="recurrence"
                    disabled={permissions && permissionDisable(permissions, values?.product?.subcategory?.category_id)}
                  >
                    {recurrences.map(item => {
                      return (
                        <Select.Option placeholder={t('messages:select')} key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  error={errors.started_at}
                  cols={{ xs: 8 }}
                  field="started_at"
                  required
                  label={t('screens:contracts.data.started_at')}
                >
                  <DatePicker
                    format="DD/MM/Y"
                    name="started_at"
                    placeholder={t('messages:select')}
                    locale={locale}
                    onChange={value => handleExpiration({ ...values, started_at: value })}
                    disabled={
                      !permissions?.includes('@contract/editInfo') ||
                      permissionDisable(permissions, values?.product?.subcategory?.category_id)
                    }
                  />
                </FormControl>
                <FormControl
                  error={errors.length}
                  cols={{ xs: values.id ? 5 : 8 }}
                  field="length"
                  required
                  label={t('screens:contracts.data.length')}
                >
                  <InputNumber
                    name="length"
                    addonAfter="Meses"
                    onChange={value => handleExpiration({ ...values, length: value })}
                    disabled={
                      !permissions?.includes('@contract/editInfo') ||
                      permissionDisable(permissions, values?.product?.subcategory?.category_id)
                    }
                  />
                </FormControl>
                <FormControl
                  error={errors.expiration}
                  cols={{ xs: 8 }}
                  field="expiration"
                  label={t('screens:contracts.data.expiration')}
                >
                  <DatePicker
                    format="DD/MM/Y"
                    name="expiration"
                    placeholder={t('messages:select')}
                    locale={locale}
                    disabled={
                      recordData.id
                        ? renewContractExpirationField ||
                          permissionDisable(permissions, values?.product?.subcategory?.category_id)
                        : !values.id
                    }
                    onCalendarChange={{}}
                  />
                </FormControl>
                {values.id && (
                  <FormControl cols={{ xs: 3 }}>
                    <Button
                      color="primary"
                      style={{
                        marginTop: '25px',
                        width: '100%',
                        display: permissionDisable(permissions, values?.product?.subcategory?.category_id)
                          ? 'none'
                          : 'block',
                      }}
                      onClick={() => handleRenewContract(values)}
                    >
                      <BsArrowRepeat />
                    </Button>
                  </FormControl>
                )}
                <FormControl
                  error={errors.notes}
                  cols={{ xs: 24 }}
                  field="notes"
                  label={t('screens:contracts.data.notes')}
                >
                  <Input.TextArea
                    onKeyDown={handleKeyDown}
                    name="notes"
                    disabled={permissionDisable(permissions, values?.product?.subcategory?.category_id)}
                  />
                </FormControl>
              </Row>
              <Row>
                <Can permission="@contract/edit">
                  <Row>
                    <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }} field="files">
                      <Upload
                        name="files"
                        showUploadList={false}
                        multiple
                        onChange={info => {
                          setUploadList(info.fileList);
                        }}
                        fileList={uploadList}
                      >
                        <Can permission="@contract/fileDownloadUpload">
                          <Button color="default">
                            Anexar arquivo
                            <Icon type="upload" />
                          </Button>
                        </Can>
                      </Upload>
                    </FormControl>
                  </Row>
                </Can>
              </Row>
              <Row cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}>
                <FormControl>
                  <Table
                    size="small"
                    loading={loading}
                    columns={uploadList.length > 0 ? tableColumns : ''}
                    dataSource={uploadList && uploadList.map(item => ({ ...item, contract_id: values.id }))}
                  />
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}

ContractsForm.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  customerID: PropTypes.string,
  contract: PropTypes.objectOf(),
  isNewCustomer: PropTypes.bool,
  setNewCustomer: PropTypes.func,
  newCustomer: PropTypes.objectOf(),
};

ContractsForm.defaultProps = {
  visible: false,
  customerID: null,
  contract: null,
  isNewCustomer: false,
  setNewCustomer: null,
  newCustomer: null,
};
