import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  FaPlus,
  FaPencilAlt,
  FaBuilding,
  FaMale,
  FaEye,
  FaFileAlt,
  FaExclamationCircle,
  FaPaperclip,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { message, Input, Switch, Popover } from 'antd';
import formatCnpj from '@brazilian-utils/format-cnpj';
import formatCpf from '@brazilian-utils/format-cpf';
import { formatPhone } from '~/Utils';
import Pagination from '~/components/Pagination';
import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import { Table, TableActions, TableHeader } from '~/components/Table';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import history from '~/services/history';
import { SpanUpper } from '~/styles/default';

export default function Customers() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [recordData, setRecordData] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [perPage, setPerPage] = useState(10);
  const permissions = useSelector(state => state.user && state.user.permissions);

  const handleSearch = async page => {
    setLoading(true);
    try {
      const { data } = await api.get('/customers', {
        params: {
          search: searchTerm,
          page,
          perPage,
        },
      });

      setRecordData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleCustomer = (id = null) => {
    if (id) {
      history.push(`/customers/${id}/edit`);
    } else {
      history.push('/customers/create');
    }
  };

  const handleChangeActive = async record => {
    setLoading(true);
    try {
      await api.put(`/customers/${record.id}`, record);
      handleSearch(page);
      message.success(t('messages:success'));
    } catch (error) {
      const { response } = error;
      const msg = JSON.parse(response.data).message;

      if (msg === 'O Cliente tem contratos ativos!') {
        await handleSearch();
        message.error(msg);
      } else {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    handleSearch(page);
  }, [page, searchTerm, perPage]);

  const tableColumns = [
    {
      title: t('screens:customers.data.business'),
      dataIndex: 'business',
      key: 'business',
      align: 'center',
      width: '90px',
      render: (text, record) => {
        return record.business ? <FaBuilding size={18} /> : <FaMale size={22} />;
      },
    },
    {
      title: t('screens:customers.data.ssn.document'),
      dataIndex: 'ssn',
      key: 'ssn',
      render: (text, record) => <span>{record.business ? formatCnpj(text) : formatCpf(text)}</span>,
    },
    {
      title: t('screens:customers.data.name.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ position: 'relative' }}>
          {record.warning_msg && record.warning_msg !== '' && record.warning_msg !== null && (
            <Popover
              placement="top"
              title={t('screens:customers.data.warning_msg')}
              content={<p>{record.warning_msg}</p>}
              trigger={['click', 'hover']}
            >
              <FaExclamationCircle style={{ position: 'absolute', left: '-23px', top: '3px' }} />
            </Popover>
          )}
          <SpanUpper>
            {record.name}
            <small style={{ display: 'block' }}>{record.nickname}</small>
          </SpanUpper>
        </div>
      ),
    },
    {
      title: t('screens:customers.data.phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (text, record) => <span>{record.phone ? formatPhone(text) : ''}</span>,
    },
    {
      // Title: Just a space on table destinated to show the qnty of each customer contracts;
      width: '10px',
      dataIndex: 'active-contract',
      key: 'active-contract',
      align: 'center',
      render: (active, record) => (
        <TableActions>
          <Can permission="@contract/view">
            {record?.contractsAmount > 0 && (
              <Button title="Contratos" style={{ border: 'none', padding: '0' }}>
                <FaFileAlt />
                <b style={{ fontSize: '10px', fontWeight: '400' }}>{record.contractsAmount}</b>
              </Button>
            )}
          </Can>
        </TableActions>
      ),
    },
    {
      // Title: Just a space on table destinated to show the qnty of each customer contracts;
      width: '10px',
      dataIndex: 'inactive-contract',
      key: 'inactive-contract',
      align: 'center',
      render: (active, record) => (
        <TableActions>
          <Can permission="@contract/view">
            {record?.inactive_contracts > 0 && (
              <Button title="Contratos inativos" style={{ border: 'none', padding: '0' }}>
                <FaFileAlt style={{ color: 'red' }} />
                <b style={{ fontSize: '10px', fontWeight: '400' }}>{record.inactive_contracts}</b>
              </Button>
            )}
          </Can>
        </TableActions>
      ),
    },
    {
      // Title: Just a space on table destinated to show the qnty of each customer contracts;
      width: '10px',
      dataIndex: 'contract-file',
      key: 'contract-file',
      align: 'center',
      render: (active, record) => (
        <TableActions>
          <Can permission="@contract/view">
            {record?.count_contracts_files > 0 && (
              <Button title="Anexos" style={{ border: 'none', padding: '0' }}>
                <FaPaperclip />
                <b style={{ fontSize: '10px', fontWeight: '400' }}>{record.count_contracts_files}</b>
              </Button>
            )}
          </Can>
        </TableActions>
      ),
    },
    {
      title: t('screens:customers.data.actions'),
      width: '100px',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, record) => (
        <TableActions>
          <Can permission="@customer/edit">
            <Button title={t('messages:edit')} onClick={() => handleCustomer(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@customer/edit">
            <Can permission="@customer/view">
              <Button title={t('messages:view')} onClick={() => handleCustomer(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
          <Can permission="@customer/view">
            <Switch
              style={{ marginTop: '5px', marginRight: '30px' }}
              name="active"
              checked={active}
              disabled={permissions && !permissions.includes('@customer/delete')}
              onChange={e => {
                record.active = e;
                delete record.files;
                handleChangeActive(record);
              }}
            />
          </Can>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:customers.title')}>
        <Can permission="@customer/edit">
          <Button color="primary" loading={loading} onClick={() => handleCustomer()}>
            <FaPlus /> {t('screens:customers.btnNew')}{' '}
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table
          rowKey="id"
          pagination={false}
          onRow={record => {
            return {
              onDoubleClick: () => {
                handleCustomer(record.id);
              },
            };
          }}
          loading={loading}
          columns={tableColumns}
          dataSource={recordData}
        />
        <Pagination
          onChange={value => {
            setPage(value);
          }}
          meta={meta}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Box>
    </DefaultLayout>
  );
}
