import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input, Select, Form, DatePicker, InputNumber } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Tag, Spin, message, Upload, Icon, Col, Switch } from 'antd';
import { FaEye, FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import * as Yup from 'yup';
import locale from 'antd/es/date-picker/locale/pt_BR';
import PropTypes from 'prop-types';
import 'moment/locale/pt-br';
import Row from '~/components/Row';
import api from '~/services/api';
import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import FormControl from '~/components/Form/FormControl';
import FormActions from '~/components/Form/FormActions';
import errorHandler from '~/Utils/errorHandler';
import InputCurrency from '~/components/Form/InputCurrency';
import history from '~/services/history';
import { dynamicCost } from '~/Utils';
import InventoryModelsForm from '~/pages/InventoryModels/form';
import InventoryBrandsForm from '~/pages/InventoryBrands/form';
import { Table, TableActions } from '~/components/Table';
import { SpanUpper } from '~/styles/default';
import { HiddenButtonStyle } from './styles';

const initialValues = {
  active: true,
};

export default function InventoryProductsForm(props) {
  const { t } = useTranslation();
  // const noData = <Empty description={t('screens:noListData')} />;

  // const { recurrences } = useSelector(state => state.app);
  const [showUserForm, setShowUserForm] = useState(false);
  const [showBrandUserForm, setShowBrandUserForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [recordData, setRecordData] = useState(initialValues);
  const [uploadList, setUploadList] = useState([]);
  const [deletedFile, setDeletedFile] = useState([]);
  const permissions = useSelector(state => state.user && state.user.permissions);
  const [codeOrSeq, setCodeOrSeq] = useState('');
  const [codeSeq, setCodeSeq] = useState('');
  const [initialCodeValue, setInitialCodeValue] = useState({});
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState('');

  const conservationOptions = ['ATIVO', 'BAIXADO', 'VENDIDO', 'ESTOQUE', 'LOCADO'];

  const { Option } = Select;

  const storeFilesToDatabase = async (files, inventoryProductId) => {
    try {
      const formData = new FormData();
      files.forEach(file => {
        const originFile = file.originFileObj || file;
        if (originFile) {
          formData.append('files[]', originFile);
        }
      });

      await api.post(`/inventoryProducts/${inventoryProductId}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      errorHandler(error);
      console.error('Erro ao armazenar arquivos:', error);
    }
  };

  const handleDownload = async id => {
    try {
      const fileInfo = await api.get(`/inventoryProducts/files/${id}`);
      window.open(fileInfo.data.url, '_blank');
      message.success(t('messages:success'));
    } catch (error) {
      console.log('error -> handleDownload -> ', error);
      errorHandler(error);
    }
  };

  const handleRemoveUploadListItem = async (file, inventoryProductId) => {
    if (file.id) {
      const deletedFilesList = deletedFile;
      deletedFilesList.push(file);
      setDeletedFile(deletedFilesList);
      const data = uploadList.filter(item => item.id !== file.id);
      setUploadList(data);
      await api.delete(`inventoryProducts/${inventoryProductId}/files/${file.id}`);
    } else {
      const data = uploadList.filter(item => item.uid !== file.uid);
      setUploadList(data);
    }
  };

  const fetchModels = async value => {
    try {
      const { data } = await api.get('/inventoryModels', {
        params: {
          inventory_brand_id: value,
        },
      });

      setModelOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchRecord = async () => {
    const {
      match: {
        params: { id },
      },
    } = props;

    if (id) {
      const { data } = await api.get(`/inventoryProducts/${id}`);

      if (data.inventorySubCategory && data.inventorySubCategory.deleted_at !== null) {
        subCategoryOptions.push(data.inventorySubCategory);
        setSubCategoryOptions(subCategoryOptions);
      }

      if (data.inventoryCategory && data.inventoryCategory.deleted_at !== null) {
        categoryOptions.push(data.inventoryCategory);
        setCategoryOptions(categoryOptions);
      }

      if (data.inventoryBrand) {
        if (data.inventoryBrand.deleted_at !== null) {
          brandOptions.push(data.inventoryBrand);
          setBrandOptions(brandOptions);
        }
        fetchModels(data.inventory_brand_id);
      }

      if (data.inventoryModel && data.inventoryModel.deleted_at !== null) {
        modelOptions.push(data.inventoryModel);
      }

      setRecordData(data);

      if (data.code_seq) {
        setInitialCodeValue({ value: data.code_seq, field: 'code_seq' });
      } else {
        setInitialCodeValue({ value: data.code, field: 'code' });
        setCodeOrSeq(data.code);
      }

      setUploadList(data.files);
    } else {
      setRecordData(initialValues);
    }
  };

  const fetchCategories = async () => {
    const { data } = await api.get('/inventoryCategories');
    setCategoryOptions(data);
  };

  const fetchSubCategories = async () => {
    const { data } = await api.get('/inventorySubCategories');
    setSubCategoryList(data);
  };

  const fetchBrands = async () => {
    const { data } = await api.get('/inventoryBrands');
    setBrandOptions(data);
  };

  const fetchCustomer = async (page, search) => {
    if (!hasMore) return;
    setSelectLoading(true);
    try {
      const response = await api.get('/customers', {
        params: {
          page,
          perPage: 20,
          name: search,
        },
      });

      const { data: newOptions, meta } = response.data;

      if (page === 1 || customerOptions === null) {
        setCustomerOptions(newOptions);
      } else {
        setCustomerOptions(prevCustomers => [...prevCustomers, ...newOptions]);
      }

      setHasMore(page < meta.last_page);
    } catch (error) {
      errorHandler(error);
    }
    setSelectLoading(false);
  };

  const fetchCodeReq = async () => {
    const { data } = await api.get('/inventoryProducts/make-code-seq');
    setCodeSeq(data);
  };

  const fetchScreen = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchCategories(), fetchSubCategories(), fetchBrands(), fetchRecord(), fetchCodeReq()]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangeSubCategories = value => {
    const options = subCategoryList.filter(subCategory => subCategory.inventory_category_id === value);
    setSubCategoryOptions(options);
    const data = options.map(option => {
      return option.id === recordData.inventory_sub_category_id;
    });
    if (!data.includes(true)) {
      setRecordData({ ...recordData, inventory_category_id: value, inventory_sub_category_id: null });
    }
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      if (values.id) {
        delete values.files;
        values.acquisition_value = dynamicCost(values.acquisition_value);

        await api.put(`/inventoryProducts/${values.id}`, values);

        if (uploadList.length > 0) {
          const uploadFiles = uploadList.map(file => (file.originFileObj ? file : null)).filter(file => file !== null);

          if (uploadFiles.length > 0) {
            await storeFilesToDatabase(uploadFiles, values.id);
          }
        }

        message.success(t('messages:successUpdateProduct'));
      } else {
        if (codeOrSeq === '') {
          delete values.code;
        } else {
          delete values.code_seq;
        }

        const { data } = await api.post('/inventoryProducts', values);

        if (uploadList.length > 0) {
          const uploadFiles = uploadList.map(file => (file.originFileObj ? file : null)).filter(file => file !== null);

          if (uploadFiles.length > 0) {
            await storeFilesToDatabase(uploadFiles, data.id);
          }
        }

        message.success(t('messages:successProduct'));
      }

      setUploadList([]);
      history.push(`/inventoryProducts`);
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const handleBack = () => {
    history.push('/inventoryProducts');
  };

  const handleStatusActive = change => {
    if (change === 'BAIXADO' || change === 'VENDIDO') {
      setRecordData({ ...recordData, status: change, active: 0 });
    } else {
      setRecordData({ ...recordData, status: change, active: 1 });
    }
  };

  const handleScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const count = scrollHeight - scrollTop <= clientHeight;

    if (count && !selectLoading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleSearch = () => {
    setHasMore(true);
    setPage(1);
    fetchCustomer(page, search);
  };

  useEffect(() => {
    fetchCustomer(page, search);
  }, [page]);

  useEffect(() => {
    fetchScreen();
  }, []);

  useEffect(() => {
    if (recordData.inventory_category_id) {
      handleChangeSubCategories(recordData.inventory_category_id);
    }
  }, [recordData.inventory_category_id]);

  const productSchema = Yup.object().shape({
    code_seq: Yup.string().nullable(),
    inventory_category_id: Yup.number().required(),
    inventory_sub_category_id: Yup.number().nullable(),
    inventory_brand_id: Yup.number().nullable(),
    inventory_model_id: Yup.number().nullable(),
    name: Yup.string().required(),
    serial: Yup.string().nullable(),
    department: Yup.string().nullable(),
    acquisition_nf: Yup.string().nullable(),
    provider: Yup.string().nullable(),
    acquisition_date: Yup.string().nullable(),
    acquisition_value: Yup.string().nullable(),
    status: Yup.string().required(),
    notes: Yup.string()
      .nullable()
      .test('oneOfRequired', 'No caso de VENDA ou BAIXA é necessário preencher este campo', () => {
        const status = Yup.ref('status');
        const notes = Yup.ref('notes');
        if (status !== 'VENDA' && status !== 'BAIXA') {
          return true;
        }
        if (notes != null) {
          return true;
        }
        return false;
      }),
  });

  const tableColumns = [
    {
      title: t('screens:contracts.data.file'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <SpanUpper>{record.name}</SpanUpper>,
    },
    {
      title: t('screens:contracts.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      render: (text, record) => {
        return (
          <TableActions>
            {record.id && (
              <Can permission="@contract/fileDownloadUpload">
                <Button
                  onClick={() => {
                    handleDownload(record.id);
                  }}
                >
                  <FaEye />
                </Button>
              </Can>
            )}
            <Can permission="@contract/delete">
              <Button
                title={t('messages:delete')}
                onClick={() => {
                  handleRemoveUploadListItem(record, record.inventory_product_id);
                }}
              >
                <FaRegTrashAlt color="danger" />
              </Button>
            </Can>
          </TableActions>
        );
      },
    },
  ];

  return (
    <DefaultLayout>
      <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Col>
          <PageTitle
            title={t('screens:inventoryProducts.titleForm')}
            subtitle={recordData.name ? `editando - ${recordData.name}` : null}
          />
          <PageTitle size={2} title={t('screens:recordData')} />
        </Col>
        <Col>
          <Col style={{ alignSelf: 'end', justifySelf: 'end' }}>
            <span>
              {`Data do Cadastro
            ${
              recordData.created_at
                ? new Date(recordData.created_at).toLocaleString('pt-BR', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : ' - indefinida'
            }`}
            </span>
          </Col>
          <Col>
            <span>
              {recordData?.updated_at &&
                ` Alterado em ${
                  recordData?.updated_at
                    ? new Date(recordData.updated_at).toLocaleString('pt-BR', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                    : ' - indefinida'
                }`}
              {recordData?.updated_by_user?.name &&
                ` por
                ${recordData?.updated_by_user?.name}`}
            </span>
          </Col>
        </Col>
      </Col>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={recordData}
        enableReinitialize
        onSubmit={handleSave}
        validationSchema={productSchema}
      >
        {({ errors, setValues, values }) => (
          <Spin spinning={loading} key={0}>
            <Form
              onBlur={e => {
                // let field = e.target.name;
                const { value } = e.target;
                setRecordData({ ...values, field: value });
              }}
            >
              <Input type="hidden" name="id" />
              <Box>
                <Row>
                  {recordData.id ? (
                    <FormControl
                      error={errors.code_seq}
                      cols={{ xs: 2 }}
                      field={initialCodeValue.field}
                      label={t('screens:inventoryProducts.data.code')}
                    >
                      <>
                        <HiddenButtonStyle />
                        <Input
                          controls="false"
                          // disabled={!recordData.active}
                          placeholder={codeSeq}
                          disabled={
                            initialCodeValue.field === 'code_seq' || !permissions?.includes('@inventoryProducts/edit')
                          }
                          className="input-code"
                          name={initialCodeValue.field}
                          value={
                            initialCodeValue.value !== null &&
                            initialCodeValue.value !== undefined &&
                            initialCodeValue.value !== ''
                              ? initialCodeValue.value
                              : ''
                          }
                          onChange={event => {
                            const newValue = event.target.value;

                            // Remove any leading zeros before setting the value in state
                            const numericValue = newValue.replace(/^0+/, '') || '';

                            setCodeOrSeq(numericValue); // Atualiza o estado com o valor numérico
                            setInitialCodeValue(prev => ({
                              ...prev,
                              value: numericValue,
                            }));
                          }}
                          onBlur={() => {
                            // Aplica padStart apenas quando o input perder o foco
                            if (initialCodeValue.value !== '') {
                              setInitialCodeValue(prev => ({
                                ...prev,
                                value: prev.value.toString().padStart(4, '0'),
                              }));
                            }
                          }}
                        />
                      </>
                    </FormControl>
                  ) : (
                    <FormControl
                      error={errors.code_seq}
                      cols={{ xs: 2 }}
                      field={codeOrSeq === '' ? 'code_seq' : 'code'}
                      label={t('screens:inventoryProducts.data.code')}
                    >
                      <>
                        <HiddenButtonStyle />
                        <InputNumber
                          controls="false"
                          min={0}
                          max={9999}
                          // disabled={!recordData.active}
                          placeholder={codeSeq}
                          className="input-code"
                          name={codeOrSeq === '' ? 'code_seq' : 'code'}
                          onChange={event => setCodeOrSeq(event)}
                        />
                      </>
                    </FormControl>
                  )}
                  <FormControl error={errors.code_seq} cols={{ xs: 2 }} label="." style={{ color: 'white' }}>
                    <Tag className="TagHeight" color={codeOrSeq !== '' && codeOrSeq !== null ? 'blue' : 'green'}>
                      {codeOrSeq !== '' && codeOrSeq !== null ? 'Patrimônio' : 'Controle'}
                    </Tag>
                  </FormControl>
                  <FormControl
                    cols={{ xs: 1 }}
                    field="active"
                    style={{ display: 'flex', justifyContent: 'end', marginTop: '10' }}
                  >
                    <Switch
                      disabled
                      checked={values.active}
                      onChange={e => {
                        setValues({ ...values, active: e });
                      }}
                      name="active"
                      // style={{ margin: 'auto', marginTop: '5px', backgroundColor: values.active ? '#43b7cc' : 'lightGrey'}}
                      style={{ margin: 'auto', marginTop: '30px' }}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    error={errors.inventory_category_id}
                    cols={{ xs: 8 }}
                    field="inventory_category_id"
                    label={t('screens:inventoryProducts.data.inventoryCategory')}
                    required
                  >
                    <Select
                      disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                      showSearch
                      name="inventory_category_id"
                      optionFilterProp="children"
                      onChange={handleChangeSubCategories}
                      placeholder="Selecione..."
                      style={{ textTransform: 'uppercase' }}
                    >
                      {categoryOptions.map(item => {
                        return (
                          <Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    error={errors.inventory_sub_category_id}
                    cols={{ xs: 8 }}
                    field="inventory_sub_category_id"
                    label={t('screens:inventoryProducts.data.inventorySubCategory')}
                  >
                    <Select
                      disabled={subCategoryOptions.length <= 0 || !permissions?.includes('@inventoryProducts/edit')}
                      style={{ textTransform: 'uppercase' }}
                      showSearch
                      placeholder="Selecione..."
                      optionFilterProp="children"
                      onChange={value => {
                        setRecordData({ ...recordData, inventory_sub_category_id: value });
                      }}
                      name="inventory_sub_category_id"
                    >
                      {subCategoryOptions.map(item => {
                        return (
                          <Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    cols={{ xs: 8 }}
                    error={errors.name}
                    field="name"
                    label={t('screens:inventoryProducts.data.product')}
                    required
                  >
                    <Input
                      name="name"
                      style={{ textTransform: 'uppercase' }}
                      disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                    />
                  </FormControl>
                  <FormControl
                    error={errors.inventory_brand_id}
                    cols={{ xs: 6 }}
                    field="inventory_brand_id"
                    label={t('screens:inventoryProducts.data.inventoryBrand')}
                  >
                    <Select
                      style={{ textTransform: 'uppercase' }}
                      showSearch
                      optionFilterProp="children"
                      name="inventory_brand_id"
                      placeholder="Selecione..."
                      onSelect={value => {
                        fetchModels(value);
                        setRecordData({ ...recordData, inventory_brand_id: value, inventory_model_id: '' });
                      }}
                      disabled={
                        brandOptions.length <= 0 || (permissions && !permissions.includes('@inventoryProducts/edit'))
                      }
                    >
                      {brandOptions.map(item => {
                        return (
                          <Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Col xs={2}>
                    <Can permission="@inventoryProducts/edit">
                      <Button
                        style={{ marginTop: '25px' }}
                        color="primary"
                        loading={loading}
                        onClick={() => setShowBrandUserForm(true)}
                      >
                        <FaPlus />
                      </Button>
                    </Can>
                  </Col>
                  <FormControl
                    error={errors.inventory_model_id}
                    cols={{ xs: 6 }}
                    field="inventory_model_id"
                    label={t('screens:inventoryProducts.data.model')}
                  >
                    <Select
                      style={{ textTransform: 'uppercase' }}
                      showSearch
                      placeholder="Selecione..."
                      optionFilterProp="children"
                      onChange={value => {
                        setRecordData({ ...recordData, inventory_model_id: value });
                      }}
                      name="inventory_model_id"
                      disabled={
                        (modelOptions.length < 1 && !recordData?.inventory_model_id) ||
                        (permissions && !permissions.includes('@inventoryProducts/edit'))
                      }
                    >
                      {modelOptions.map(item => {
                        return (
                          <Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Col xs={2}>
                    <Button
                      style={{
                        marginTop: '25px',
                        display: permissions && !permissions.includes('@inventoryProducts/edit') ? 'none' : 'block',
                      }}
                      color="primary"
                      loading={loading}
                      onClick={() => setShowUserForm(true)}
                    >
                      <FaPlus />
                    </Button>
                  </Col>
                  <FormControl
                    cols={{ xs: 8 }}
                    error={errors.serial}
                    field="serial"
                    label={t('screens:inventoryProducts.data.serial')}
                  >
                    <Input
                      name="serial"
                      style={{ textTransform: 'uppercase' }}
                      disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                    />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 6 }}
                    error={errors.department}
                    field="department"
                    label={t('screens:inventoryProducts.data.department')}
                  >
                    <Input
                      name="department"
                      style={{ textTransform: 'uppercase' }}
                      disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                    />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 6 }}
                    error={errors.responsible}
                    field="responsible"
                    label={t('screens:inventoryProducts.data.responsible')}
                  >
                    <Input
                      name="responsible"
                      style={{ textTransform: 'uppercase' }}
                      disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                    />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 4 }}
                    error={errors.acquisition_nf}
                    field="acquisition_nf"
                    label={t('screens:inventoryProducts.data.acquisition_nf')}
                  >
                    <Input
                      name="acquisition_nf"
                      style={{ textTransform: 'uppercase' }}
                      disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                    />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 8 }}
                    error={errors.provider}
                    field="provider"
                    label={t('screens:inventoryProducts.data.provider')}
                  >
                    <Input
                      name="provider"
                      style={{ textTransform: 'uppercase' }}
                      disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                    />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 4 }}
                    error={errors.acquisition_date}
                    field="acquisition_date"
                    label={t('screens:inventoryProducts.data.acquisition_date')}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      name="acquisition_date"
                      placeholder={t('messages:select')}
                      locale={locale}
                      disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                    />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 4 }}
                    error={errors.acquisition_value}
                    field="acquisition_value"
                    label={t('screens:inventoryProducts.data.acquisition_value')}
                  >
                    <InputCurrency
                      name="acquisition_value"
                      currency="R$"
                      number={false}
                      value={(values?.acquisition_value && dynamicCost(values?.acquisition_value)) ?? '0,00'}
                      style={{ textAlign: 'right', textTransform: 'uppercase' }}
                      onChange={event => {
                        setTimeout(() => {
                          setValues({ ...values, acquisition_value: dynamicCost(event.target.value) });
                        }, 100);
                      }}
                      disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                    />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 4 }}
                    error={errors.status}
                    field="status"
                    label={t('screens:inventoryProducts.data.conservation')}
                  >
                    <Select
                      style={{ textTransform: 'uppercase' }}
                      showSearch
                      optionFilterProp="children"
                      onChange={value => {
                        handleStatusActive(value);
                      }}
                      name="status"
                      disabled={
                        conservationOptions.length <= 0 ||
                        (permissions && !permissions.includes('@inventoryProducts/edit'))
                      }
                    >
                      {conservationOptions.map(item => {
                        return (
                          <Option key={item} value={item} style={{ textTransform: 'uppercase' }}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>

                  {values.status === 'LOCADO' && (
                    <FormControl
                      cols={{ xs: 8 }}
                      error={errors.customer_id}
                      required
                      field="customer_id"
                      label={t('screens:contracts.data.customer_id')}
                    >
                      <Select
                        name="customer_id"
                        showSearch
                        filterOption={false}
                        onSearch={value => {
                          setSearch(value);
                          setCustomerOptions(null);
                        }}
                        onInputKeyDown={e => {
                          console.log(e.key);
                          if (e.key === 'Enter') {
                            console.log('entrou!');
                            e.preventDefault();
                            handleSearch(); // Chama handleSearch apenas quando "Enter" é pressionado
                          }
                        }}
                        onPopupScroll={handleScroll}
                        defaultActiveFirstOption={false}
                        placeholder={t('messages:select')}
                        onSelect={(value, option) => {
                          setCustomerName(option?.props?.children);
                          setRecordData({ ...recordData, customer_id: value });
                        }}
                        disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                        style={{ textTransform: 'uppercase' }}
                        notFoundContent={selectLoading ? <Spin /> : 'No more data'}
                        value={values?.customer?.name ?? customerName}
                      >
                        {customerOptions?.length > 0 &&
                          customerOptions.map(item => {
                            return (
                              <Select.Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                                {!item.active ? <b style={{ color: '#ff000078' }}>{item.name}</b> : item.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}

                  <FormControl
                    cols={{ xs: 24 }}
                    error={errors.notes}
                    field="notes"
                    label={t('screens:inventoryProducts.data.notes')}
                  >
                    <Input.TextArea
                      name="notes"
                      style={{ textTransform: 'uppercase' }}
                      disabled={permissions && !permissions.includes('@inventoryProducts/edit')}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <Can permission="@inventoryProducts/edit">
                    <Row>
                      <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }} field="files">
                        <Upload
                          name="files"
                          showUploadList={false}
                          multiple
                          onChange={info => {
                            setUploadList(info.fileList);
                          }}
                          fileList={uploadList}
                        >
                          <Can permission="@contract/fileDownloadUpload">
                            <Button color="default">
                              Anexar arquivo
                              <Icon type="upload" />
                            </Button>
                          </Can>
                        </Upload>
                      </FormControl>
                      <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}>
                        <Table
                          size="small"
                          loading={loading}
                          columns={uploadList.length > 0 ? tableColumns : ''}
                          dataSource={
                            uploadList && uploadList.map(item => ({ ...item, inventory_product_id: values.id }))
                          }
                        />
                      </FormControl>
                    </Row>
                  </Can>
                </Row>
                <Row>
                  <FormActions>
                    <Button onClick={handleBack}>Cancelar</Button>
                    <Can permission="@inventoryProducts/edit">
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                    </Can>
                  </FormActions>
                </Row>
              </Box>
            </Form>
          </Spin>
        )}
      </Formik>

      <InventoryModelsForm
        visible={showUserForm}
        onClose={() => {
          setShowUserForm(false);

          if (recordData?.inventory_brand_id) {
            fetchModels(recordData.inventory_brand_id);
          }
        }}
        recordID={+false}
      />
      <InventoryBrandsForm
        visible={showBrandUserForm}
        onClose={() => {
          setShowBrandUserForm(false);
          fetchBrands();
        }}
        recordID={+false}
      />
    </DefaultLayout>
  );
}

InventoryProductsForm.propTypes = {
  match: PropTypes.oneOfType([PropTypes.node]),
};

InventoryProductsForm.defaultProps = {
  match: null,
};
